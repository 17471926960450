import React from 'react';
import { Link } from 'gatsby';
import Seo from '../components/SEO';
import useSound from 'use-sound';
import Click from '../sounds/click.mp3';

import Base from '../svg/collections/base.svg';
import Elementary from '../svg/collections/elementary.svg';
import Stories from '../svg/collections/stories.svg';
import Limited from '../svg/collections/limited.svg';
import Workings from '../svg/collections/workings.svg';

function CollectionsPage() {
  const [click] = useSound(Click, { volume: 0.1 });

  function Collection({ title, desc, link, img, wip }) {
    return (
      <Link
        to={link}
        onClick={click}
        className={`${
          wip ? 'blur-sm' : ''
        } flex flex-col items-center justify-around h-full p-16`}
      >
        {img}
        <div>
          <p className="flex flex-col items-center mt-16 font-serif text-4xl font-bold text-center text-primary">
            {title}
          </p>
          <p className="flex flex-col items-center my-4 font-mono text-sm text-center text-primary ">
            {desc}
          </p>
        </div>
      </Link>
    );
  }

  return (
    <section>
      <Seo pageTitle="collections" />

      <div
        className="flex flex-row items-center justify-between w-full mt-16 text-white bg-primary md:mt-20 lg:mt-12"
      >
        <h1 className="p-4 text-4xl font-bold md:text-4xl">collections</h1>
        <Link className="p-4" to="/en/">
          see all
        </Link>
      </div>
      <div className="flex flex-wrap products">
        <div className="product text-primary hover:border-primary md:w-2/4 ">
          <Collection
            img={<Base height="100%" width="70%" />}
            title="base line"
            desc="fountational set; simple design on premium fabrics"
            link="/en/collections/base"
          />
        </div>
        <div className="product text-primary hover:border-primary md:w-2/4 ">
          <Collection
            img={<Limited height="100%" width="60%" />}
            title="limited"
            desc="our small batch, one off collections"
            link="/en/collections/limited"
          />
        </div>
        <div className="product text-primary hover:border-primary md:w-2/4">
          <Collection
            img={<Workings height="100%" width="50%" />}
            title="the workings"
            desc="the journey from plant to packaging"
            link="/wip"
            wip={true}
          />
        </div>
        <div className="product text-primary hover:border-primary md:w-2/4">
          <Collection
            img={<Elementary height="100%" width="70%" />}
            title="elementary"
            desc="fundamentals of arts, the building blocks"
            link="/wip"
            wip={true}
          />
        </div>
        <div className="product text-primary hover:border-primary md:w-2/4">
          <Collection
            img={<Stories height="100%" width="60%" />}
            title="stories"
            desc="pieces inspired by stories of past life"
            link="/wip"
            wip={true}
          />
        </div>
      </div>
      <div style={{ marginTop: '-2px' }} className="flex flex-col items-center">
        <h1 className="flex items-center h-screen text-4xl font-bold text-primary">
          more coming soon
        </h1>
      </div>
    </section>
  );
}

export default CollectionsPage;
